function photoSwipe() {
    var pswpTemplate = '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true"><div class="pswp__bg"></div><div class="pswp__scroll-wrap"><div class="pswp__container"><div class="pswp__item"></div><div class="pswp__item"></div><div class="pswp__item"></div></div><div class="pswp__ui pswp__ui--hidden"><div class="pswp__top-bar"><div class="pswp__counter"></div><button class="pswp__button pswp__button--close" title="Close (Esc)"></button><button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button><button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button><div class="pswp__preloader"><div class="pswp__preloader__icn"><div class="pswp__preloader__cut"><div class="pswp__preloader__donut"></div></div></div></div></div><div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"><div class="pswp__share-tooltip"></div></div><button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button><button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button><div class="pswp__caption"><div class="pswp__caption__center"></div></div></div></div></div>';
    $('body').append(pswpTemplate);
    var gallerySelector = ".gallery-popup figure";

    var elements = $(gallerySelector);
    var parseThumbnailElements = function () {
        var items = [];
        elements.each(function () {
            var linkEl = $(this).children().eq(0);
            var size = linkEl.data("size").split("x");

            items.push({
                src: linkEl.data("href"),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10),
                minZoom: 3,
                el: this,
                msrc: linkEl.children().eq(0).attr("src")
            });
        });

        return items;
    };

    var openPhotoSwipe = function (index, galleryElement) {
        var pswpElement = document.querySelectorAll(".pswp")[0];
        var items = parseThumbnailElements();
        var options = {
            maxSpreadZoom: 5,
            bgOpacity: 0.7,
            galleryUID: galleryElement.getAttribute("data-pswp-uid"),
            getThumbBoundsFn: function (index) {
                var thumbnail = items[index].el.getElementsByTagName("img")[0],
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();
                return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
            },
            minZoom: 3,
            index: parseInt(index)
        };

        return (new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options)).init();
    };

    elements.each(function(index) {
        var self = $(this);
        self.attr("data-pswp-uid", index + 1);
        self.click(function(e) {
            e.preventDefault();
            var self = $(this);

            openPhotoSwipe(self.data('pswp-uid') - 1, self.parent().get(0));
        });
    });
}

function startCarousel() {
    $('.owl-carousel').each(function() {
        var self = $(this);
        if (this.id == 'owl-product') {
            var owl = self.owlCarousel({
                items: 1,
                lazyLoad: true,
                nav: true,
                navText: ['<i class="fas fa-chevron-circle-left"></i>', '<i class="fas fa-chevron-circle-right"></i>'],
                navContainer: self.next(),
                lazyLoadEager: 2,
                autoHeight: false,
                dots: false
            });

            var productPage = $('#product-page');
            function resizeCalculation() {
                var container = productPage.find('#owl-product');
                var images = container.find('img');
                if ($(window).width() > 959) {
                    container.css('max-height', '');
                    container.css('max-width', '');
                    images.css('height', '');
                    images.css('width', '');
                    return;
                }
                var top = productPage.find('#image-anchor').offset().top;
                var imgHeight = productPage.find('#owl-product').height();
                var bottom = productPage.find('.owl-custom-thumbnails').offset().top - top - imgHeight;

                var height = $(window).height() - top - bottom;
                var width = $(window).width() - 70;
                var maxSize = Math.floor(Math.max(150, Math.min(225, height, width)));

                container.css('max-height', maxSize);
                container.css('max-width', maxSize);
                images.css('height', maxSize);
                images.css('width', maxSize);

                owl.trigger('refresh.owl.carousel');
            }

            resizeCalculation();
            $(window).resize(_.debounce(resizeCalculation, 200));

            const thumbnails = document.querySelectorAll('.js-thumbnail')
            if (thumbnails.length){
                thumbnails.forEach((thumb, index) => {
                    thumb.addEventListener('click', () => {
                        owl.trigger('to.owl.carousel', index)
                    })
                })
                owl.on('changed.owl.carousel', (e) => {
                    thumbnails.forEach((thumb, index) => {
                        if (e.item.index === index) {
                            thumb.classList.add('active')
                        } else {
                            thumb.classList.remove('active')
                        }
                    })
                })
            }
            return;
        }

        if (self.parent().hasClass('owl-custom-theme')) {
            var data = self.data('owlCustom');
            if (data !== Object(data)) data = {};
            return self.owlCarousel(_.defaults(data, {
                items: 1,
                lazyLoad: true,
                nav: true,
                navText: ['<i class="fas fa-chevron-circle-left"></i>', '<i class="fas fa-chevron-circle-right"></i>'],
                navContainer: self.next(),
                lazyLoadEager: 1,
                dots: false
            }));
        }

        return self.owlCarousel({
            items: 1,
            lazyLoad: true
        });
    });
}
$(function () {
    if (!$('.owl-carousel').length) return;
    $.ajaxSetup({
        cache: true
    });

    if ($('.gallery-popup').length) {
        $.when(
            $.getScript( "//prod.cdn.plein.nl/js/vendor/owl.carousel.min.js" ),
            $.getScript( "//prod.cdn.plein.nl/js/vendor/photoswipe.min.js" ),
            $.getScript( "//prod.cdn.plein.nl/js/vendor/photoswipe-ui-default.min.js" ),
            $.Deferred(function(deferred) { $(deferred.resolve); })
        ).done(function() {
            photoSwipe();
            startCarousel();
        });
    } else {
        $.when(
            $.getScript( "//prod.cdn.plein.nl/js/vendor/owl.carousel.min.js" ),
            $.Deferred(function(deferred) { $(deferred.resolve); })
        ).done(function() {
            startCarousel()
        });
    }
});

