$(function(){
    $('.plein-accordion-container').each(function () {
        $(this).find('.plein-accordion-content').hide();
        $(this).find('.plein-accordion-content').animate({
            height: "toggle"
        }, 10, function() {
            // Animation complete.
        });
        $(this).find('h3').on('click', function () {
            var me = $(this);
            me.next('.plein-accordion-content').stop().animate({
                height: "toggle"
            }, 500, function() {
                me.next('.plein-accordion-content').toggleClass('open');
            });
        })
    })
});
