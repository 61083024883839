if(document.querySelector('.open-side-menu') !== null){
    document.querySelector('.open-side-menu').addEventListener('click', loadMiniCart);
}

function showSpinner() {
    const targetElement = document.querySelector('.mini-cart__ajax');
    const loadingElement = document.createElement("div");
    const loadingSpinner = document.createElement("div")
    loadingElement.style.cssText = "min-height: 100%; min-width: 100%; display: flex; align-items: center; justify-content: center; flex-grow: 1;"
    loadingSpinner.classList.add("mini-cart__loading");
    loadingElement.appendChild(loadingSpinner)
    targetElement.replaceChildren(loadingElement);
}

function loadMiniCart() {
    const targetElement = document.querySelector('.mini-cart__ajax');
    // Only show spinner if there isn't one being shown already.
    if (!targetElement.querySelector('.mini-cart__loading')) {
        showSpinner()
    }
    fetch(window.location.origin + '/minicart')
        .then(response => {
            if (response.ok) {
                return response.text(); // Assuming the response is HTML

            }
            throw new Error('Network response was not ok.');
        })
        .then(html => {
            document.querySelector('.mini-cart__ajax').innerHTML = html;
            updateCounter()
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });

}

function modifyCart(id, amount) {
    const tempProduct = document.querySelector(`[data-minicart-id="${id}"]`);
    const tempProductPrice = Number(tempProduct.getAttribute('data-minicart-price'));
    showSpinner();
    if (amount === 0) {
        this.deleteFromMiniCart(id);
    } else {
        // tempProduct.querySelector('select').value = amount;
        // tempProduct.querySelector('.mini-cart__quantity-button').onclick = `modifyCart(id, ${amount+1})`;
        // tempProduct.querySelector('.mini-cart__price-total').innerHTML = formatPrice((tempProductPrice * amount));
        // tempProduct.querySelector('.mini-cart__price-total').innerHTML = formatPrice((tempProductPrice * amount));
        // tempProduct.setAttribute('data-minicart-totalprice', (tempProductPrice * amount));

        // calculateTotalPrice();

        const formData = new FormData();
        formData.append('product_id', id);
        formData.append('quantity', amount);

        fetch(window.location.origin + '/checkout/cart/add', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    this.loadMiniCart();
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }
}

function deleteFromMiniCart(id) {
    const tempRemove = document.querySelector(`[data-minicart-id="${id}"]`);
    if (tempRemove) {
        tempRemove.remove();
        calculateTotalPrice();
    }
    showSpinner();
    fetch(window.location.origin + `/checkout/cart/remove/${id}`)
        .then(response => {
            if (response.ok) {
                this.loadMiniCart();
            }
            throw new Error('Network response was not ok.');
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
}

function formatPrice(price) {
    return price.toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

function calculateTotalPrice() {
    let totalPrice = 0;
    for (const el of document.getElementsByClassName('mini-cart__product')) {
        totalPrice += Number(el.getAttribute('data-minicart-totalprice'));
    }
    document.getElementById('mini-cart__price-total-text').innerHTML = formatPrice(totalPrice);
}
function updateCounter(){
    let counter = 0;
    document.querySelector('.mini-cart__products').querySelectorAll("select").forEach((item) => {
        counter += Number(item.value);
    })
    document.querySelector('.js-cart-count').innerHTML = counter
}
