$(function(){

    // Tooltip
    var tooltipVerzendKosten = $('[data-toggle="tooltipVK"]');
    // tooltipVerzendKosten.tooltip('show');
    tooltipVerzendKosten.on("mouseover",function () {
        tooltipVerzendKosten.tooltip('show');
    });

    // Coupon field tooltip
    var tooltipTarget =  $('[data-toggle="tooltipOffFocus"]'),
        self = $(this);

    $("#coupon-trigger").click(function(){tooltipTarget.tooltip('destroy');});
    $('#coupon').blur(function()
    {
        if( !$(this).val() ) {
            tooltipTarget.tooltip('destroy');
        } else {
            tooltipTarget.tooltip('show');
        }
    });

    tooltipTarget.on("focus", function() {
        tooltipTarget.tooltip('destroy');
    });

});
