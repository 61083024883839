// jQuery extension to check scrollEnd:
$.fn.scrollEnd = function(callback, timeout) {
    $(this).scroll(function(){
        var $this = $(this);
        if ($this.data('scrollTimeout')) {
            clearTimeout($this.data('scrollTimeout'));
        }
        $this.data('scrollTimeout', setTimeout(callback,timeout));
    });
};

$(function(){
    var timer = null,
        offset = 300,
        offset_opacity = 1200,
        scroll_duration = 1000,
        $back_to_top = $('.back-to-top');

    if( $(window).scrollTop() > offset ) {
        $back_to_top.addClass('cd-is-visible');
        if( $('#launcher').length && $(window).width() > 960) $back_to_top.css({bottom: '75px'});
    }

    $(window).scroll(function(){
        if( $back_to_top.hasClass('cd-is-visible') ) $back_to_top.css("opacity","0.2");
    });

    // Call the scrollEnd with a 1000ms timeout:
    $(window).scrollEnd(function(){
        $back_to_top.css("opacity","0.9")
        if( $(this).scrollTop() > offset ) {
            $back_to_top.addClass('cd-is-visible');
            if( $('#launcher').length && $(window).width() > 960) $back_to_top.css({bottom: '75px'});
        }
        else {
            $back_to_top.removeClass('cd-is-visible').css({bottom: '25px'});
        }
    }, 500);

    $('a[href*="#"]:not([href="#"])').click(function(e) {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {

            var href = $(this).attr('href');

            if (href == '/#spinwin' || href == '#spinwin') {
                return;
            }

            e.preventDefault();

            var headerHeight = $('#header').height() + 25;

            var target 		= $(this.hash),
                target 		= target.length ? target : $('[name=' + this.hash.slice(1) +']'),
                collapse1   = $(this).data('toggle'),
                collapse2   = 'collapse';

            if(href == '#0'){
                $('html,body').animate({
                        scrollTop: 0 ,
                    }, scroll_duration
                );
            } else if ((target.length) && (collapse1 != collapse2)) {
                $('html, body').animate({
                        scrollTop: target.offset().top-headerHeight
                    }, scroll_duration
                );
                return false;
            }
        }
    });
});
