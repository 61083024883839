(function () {
    window.addEventListener('load', function () {
        const urls = document.querySelectorAll('[data-layer-poster].product-preview__umbrella, a.js-remove-card-product');
        urls.forEach(function(url) {
            url.addEventListener('click', function(e) {
                if(e.ctrlKey || e.shiftKey) return;
                e.preventDefault();
                window.dataLayer.push(JSON.parse(e.currentTarget.dataset.layerPoster))
                window.location = e.currentTarget.href;
            })
        });

        const buttons = document.querySelectorAll('.js-show-add-to-cart-modal');
        buttons.forEach(function(url) {
            url.addEventListener('click', function(e) {
                if(e.ctrlKey || e.shiftKey) return;
                window.dataLayer.push(JSON.parse(e.currentTarget.dataset.layerPoster))
            })
        });

        const forms = document.querySelectorAll('form.cart-checkout-payment');
        forms.forEach(function(form) {
            form.addEventListener('submit', function(e) {
                e.preventDefault();
                window.dataLayer.push(JSON.parse(form.querySelector('input[name=payment]:checked').dataset.layerPoster))
                e.currentTarget.submit();
            })
        });
    });
})(); 
