(function () {
    function startTimer(endDateTime, display) {
        setInterval(function () {
            var timer = endDateTime - new Date().getTime();
            if (timer < 0) {
                clearInterval();
                display.textContent = "";
            }

            var days = Math.floor(timer / (1000 * 60 * 60 * 24));
            var hours = Math.floor((timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((timer % (1000 * 60)) / 1000);

            days = days < 1 ? "" : days + "d ";
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            display.textContent = days + hours + ":" + minutes + ":" + seconds;

        }, 1000);
    }

    window.addEventListener('load', function () {
        const countdowns = document.querySelectorAll("span.countdown");

        countdowns.forEach(function(countdown) {
            startTimer(new Date(countdown.dataset.endDate).getTime(), countdown);
        });
    });
})();
