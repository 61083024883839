$(function () {

    $('.add-to-favorites-ajax,a.btn.btn-secondary.trash').on('click', function (e) {
        e.preventDefault();
        var self = $(this);
        var headerFavoriteHeart = $('div.favorite-heart');
        var numFavorites = $('div.favorite-heart span.circle');
        var numAvailableFavorites = $('#numAvailableFavorites').text();
        var totalFavorites = parseInt(numFavorites.text(),10);

        if( self.hasClass('trash') ) {
            self.addClass('disabled')
        }
        if( self.hasClass('favorite') || self.hasClass('trash')) {
            numFavorites.text(totalFavorites - 1);
        }
        else {
            numFavorites.text(totalFavorites + 1);
        }

        if(self.hasClass('add-to-favorites-ajax')) {
            self.toggleClass('favorite');
            if (self.hasClass('favorite')) {
                self.attr('title', 'Haal van mijn favorietenlijst').find('i').removeClass('far').addClass('fas');
                window.dataLayer.push(self.data('layer-poster'))
            } else {
                self.attr('title', 'Zet op mijn favorietenlijst').find('i').removeClass('fas').addClass('far');
            }
            $.get(window.location.origin + '/profile/favorites/toggle/' + self.data('js-addtofavorites'));
        }
        else if(self.hasClass('trash')) {
            $.get(window.location.origin + '/profile/favorites/toggle/' + self.attr('id'));
            self.closest('.js-product-preview').remove();
            if( numAvailableFavorites == '1') {
                $('#numAvailableFavorites').text(0);
                $('#addAllToCartButton').removeClass('disabled').addClass('disabled');
            }
            else {
                if(!self.hasClass('not')) numAvailableFavorites--;
                $('#numAvailableFavorites').text(numAvailableFavorites);
            }
            if( totalFavorites === 1 ) {
                window.location.href = '/profile/favorites/';
            }
        }

        headerFavoriteHeart.addClass('added-animation');

        setTimeout(function () {
            headerFavoriteHeart.removeClass('added-animation');
            self.removeClass('added-to-cart');
        }, 500);
    });
});

let favoritesMoreToggled = false;
function toggleMore(e){
    favoritesMoreToggled = !favoritesMoreToggled;
    const elItems = document.getElementsByClassName('favorite-filter-container')[0].getElementsByClassName('item')
    if(favoritesMoreToggled){
        e.innerHTML = 'Toon minder'
        for(let i = 3; i < elItems.length; i++){
            elItems[i].classList.add('shown')
        }
    } else {
        for(let i = 3; i < elItems.length; i++){
            e.innerHTML= 'Toon alle'
            elItems[i].classList.remove('shown')
        }
    }
}
