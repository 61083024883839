(function () {

    window.addEventListener('load', function () {
        const selectors = document.querySelectorAll("[data-pricing-table]");

        if (selectors.length) {
            const priceBlocks = document.querySelectorAll("span.product-price");
            const recommendedBlocks = document.querySelectorAll("span.product-recommended");
            const discountBlocks = document.querySelectorAll("span.product-discount-percentage");
            const discountTextBlocks = document.querySelectorAll("span.product-advice");
            const discountSelectors = [];

            selectors.forEach(function(selector) {
                const prices = JSON.parse(selector.dataset.pricingTable)
                const recommendedPrice = selector.dataset.recommendedPrice;
                updatePrice(selector.value, priceBlocks, prices, recommendedBlocks, recommendedPrice, discountBlocks, discountTextBlocks, discountSelectors);
                selector.addEventListener('change', function(e) {
                    updatePrice(e.target.value, priceBlocks, prices, recommendedBlocks, recommendedPrice, discountBlocks, discountTextBlocks, discountSelectors);
                })
            });

            discountSelectors.forEach(function(button) {
                const qty = button.dataset.promoQty;

                button.addEventListener('click', function(e) {
                    selectors.forEach(function(selector) {
                        selector.value = qty;
                        if ("createEvent" in document) {
                            var evt = document.createEvent("HTMLEvents");
                            evt.initEvent("change", false, true);
                            selector.dispatchEvent(evt);
                        }
                        else
                            selector.fireEvent("onchange");
                    });
                })
            });
        }
    });

    function updatePrice(value, priceBlocks, prices, recommendedBlocks, recommendedPrice, discountBlocks, discountTextBlocks, discountSelectors) {
        let price;
        if (!(value in prices)) {
            price = prices[Object.keys(prices)[Object.keys(prices).length - 1]]
        } else {
            price = prices[value];
        }
        let dis = discount((price * value), (recommendedPrice * value));

        priceBlocks.forEach(function(block) {
            block.innerHTML = '<strong>' + formatPrice(price * value) + '</strong>';
        })

        recommendedBlocks.forEach(function(block) {
            if (dis) {
                block.innerHTML = formatPrice(recommendedPrice * value);
            } else {
                block.innerHTML = '';
            }
        })

        discountTextBlocks.forEach(function(block) {
            if (dis) {
                block.innerHTML = '(adviesprijs) ' + '<span data-tooltip="De aanbevolen verkoopprijs bepaald door een fabrikant of leverancier. Klik voor meer informatie."><a href="/klantenservice/adviesprijzen-en-kortingen/"><i class="far fa-info-circle extra-info" aria-hidden="true"></i></a></span>'
            } else {
                block.innerHTML = '';
            }
        })

        discountBlocks.forEach(function(block) {
            if (dis) {
                block.innerHTML = dis + '% korting';
            } else {
                block.innerHTML = '';
            }
        })

        discountSelectors.forEach(function(block) {
            if (value === block.dataset.promoQty) {
                block.classList.add("active");
            } else {
                block.classList.remove("active");
            }
        })
    }

    function discount(price, recommendedPrice) {
        if (recommendedPrice <= 0) return false;

        let per = (recommendedPrice - price) / recommendedPrice * 100;
        if (per < 4.9) return false;

        return new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0  }).format(per);
    }

    function formatPrice(price) {
        return new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2  }).format(price);
    }
})();
