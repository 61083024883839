$(function () {
    $('.toggle-consent').on('change', function (e) {
        e.preventDefault();

        var consentName = $(this).data('js-toggleconsent');
        window.dataLayer.push($(this).data('layer-poster'));

        $.ajax({
            type: "GET",
            url: window.location.origin + '/cookies/toggle-consent/' + consentName
        });
    });
});
