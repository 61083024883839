
const cart = document.getElementById('mini-cart');
const background = document.getElementById('menu-bg');

let miniCartState = {
    cartOpen: false
};
function openCart(){
    loadMiniCart();
    miniCartState.cartOpen = true;
    cart.classList.add('mini-cart__open');
    background.classList.add('menu-bg-open');
    background.addEventListener('click', closeCart);
}
function closeCart() {
    miniCartState.cartOpen = false;
    cart.classList.remove('mini-cart__open');
    background.classList.remove('menu-bg-open');
    background.removeEventListener('click', closeCart);
}

function toggleCart() {
    miniCartState.cartOpen ? closeCart() : openCart();
}
// Toggle button
if(document.querySelector('.open-side-menu') !== null
    && document.querySelector('.mini-cart__close') !== null)
{
    document.querySelector('.open-side-menu').addEventListener('click', openCart);

    document.querySelector('.mini-cart__close').addEventListener('click', closeCart)
}
