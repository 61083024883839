let email_suggestion = {
    "0lanet" : "planet",
    "ail" : "gmail",
    "cs4all" : "xs4all",
    "dmail" : "gmail",
    "email" : "gmail",
    "g.comail" : "gmail",
    "gail" : "gmail",
    "gamil" : "gmail",
    "gemail" : "gmail",
    "ghmail" : "gmail",
    "gkail" : "gmail",
    "glail" : "gmail",
    "gmaail" : "gmail",
    "gmaill" : "gmail",
    "gmal" : "gmail",
    "gmall" : "gmail",
    "gmatl" : "gmail",
    "gmial" : "gmail",
    "gmmail" : "gmail",
    "hhotmail" : "hotmail",
    "hitmail" : "hotmail",
    "hmail" : "hotmail",
    "hogmail" : "hotmail",
    "homail" : "hotmail",
    "hormail" : "hotmail",
    "hotamail" : "hotmail",
    "hotmai" : "hotmail",
    "hotmaim" : "hotmail",
    "hotmain" : "hotmail",
    "hotmaio" : "hotmail",
    "hotmaip" : "hotmail",
    "hotmal" : "hotmail",
    "hotmamil" : "hotmail",
    "hotmaol" : "hotmail",
    "hotmaul" : "hotmail",
    "hotmmail" : "hotmail",
    "hotmsil" : "hotmail",
    "hotnail" : "hotmail",
    "hotnsil" : "hotmail",
    "hottmail" : "hotmail",
    "hoymail" : "hotmail",
    "hptmail" : "hotmail",
    "htomail" : "hotmail",
    "icloid" : "icloud",
    "ipcmail" : "upcmail",
    "ive" : "live",
    "jotmail" : "hotmail",
    "knmail" : "kpnmail",
    "knpplant" : "kpnplanet",
    "kommail" : "kpnmail",
    "konmail" : "kpnmail",
    "konplanet" : "kpnplanet",
    "kpail" : "kpnmail",
    "kpnmaik" : "kpnmail",
    "kpnmaol" : "kpnmail",
    "kpnmsil" : "kpnmail",
    "kpnplaneg" : "kpnplanet",
    "kpnplanert" : "kpnplanet",
    "libe" : "live",
    "lieve" : "live",
    "liive" : "live",
    "line" : "live",
    "liv" : "live",
    "llanet" : "planet",
    "mail" : "gmail",
    "ms" : "msn",
    "olanet" : "planet",
    "onet" : "planet",
    "onlein" : "online",
    "onlin" : "online",
    "onlinee" : "online",
    "otlook" : "outlook",
    "otmail" : "hotmail",
    "outlock" : "outlook",
    "outlool" : "outlook",
    "ppanet" : "planet",
    "qgmail" : "gmail",
    "qmail" : "gmail",
    "tahoo" : "yahoo",
    "teleent" : "telenet",
    "tlelenet" : "telenet",
    "uahoo" : "yahoo",
    "uocmail" : "upcmail",
    "upcmai" : "upcmail",
    "upcmaik" : "upcmail",
    "upcmaip" : "upcmail",
    "upcmsil" : "upcmail",
    "upcnail" : "upcmail",
    "uupcmail" : "upcmail",
    "xs4al" : "xs4all",
    "xs4ll" : "xs4all",
    "yaho" : "yahoo",
    "yahool" : "yahoo",
    "zigggo" : "ziggo",
    "ziggi" : "ziggo"
}

