$(function() {
    var productPage = $('#product-page');
    if (!productPage.length) return;

    productPage.find('.product-details-toggle').click(function() {
        var self = $(this);
        var details = $('#product-page .product-details');

        details.toggleClass('product-details-hidden');
        if (details.hasClass('product-details-hidden')) {
            details.slideUp('fast');
            self.html('<span>Lees meer <i class="fas fa-chevron-circle-right" aria-hidden="true"></i></span>');
        } else {
            details.slideDown('fast');
            self.html('<span>Lees minder <i class="fas fa-chevron-circle-up" style="transform: rotate(0deg)" aria-hidden="true"></i></span>');
        }
    });
});
