$(function () {
    $('.star-container').each( function () {
        $(this).on('click', 'li', function () {
            var id = $(this).attr("id");

            $(this).siblings().each(function () {
                if ($(this).attr("id") > id ) {
                    $(this).addClass('star-empty').removeClass('star-filled');
                } else {
                    $(this).addClass('star-filled').removeClass('star-empty');
                }
            });

            $(this).addClass('star-filled').removeClass('star-empty');

            var cid = $(this).parent().parent().attr("id");
            $("." + cid).val(id);
        });
    });


    $('.review').each(function (e) {
        var height = $(this).height(),
            review = $(this);

        if (height >= 310) {
            review.addClass('toggleActive');

            review.find('.review-toggle').on('click', function () {
                review.toggleClass('full');
            })
        }

    })
});