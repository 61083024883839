(function () {
    function startTimer(timeout, display) {
        setInterval(function () {
            clearInterval();
            display.classList.add('hide');
        }, timeout);
    }

    window.addEventListener('load', function () {
        const messages = document.querySelectorAll("div.message--success");
        messages.forEach(function(message) {
            startTimer(message.dataset.timeout, message);
        });
    });
})();
