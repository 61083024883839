$(function() {

    var snackbarHeight, snackbarTarget, snackbarContent, snackbarHeightPadding, backToTopHeight, bottomItemsPosition;

    if ($('.cookie-dismissible:visible').length != 0) {
        var cookieHeight = $('.cookie-dismissible').height();
        $('.zopim').css({bottom: cookieHeight});
    }

    $('.cookie-dismissible .btn').each(function () {
        $(this).on('click', function() {
            $('.cookie-dismissible').css({display: 'none'});
        });
    });

    $(document).on('click', '[data-js-snackbar]', function (e) {
        if($(window).width() <= 1024 ) {
            snackbarTarget = $('#snackbar');
            snackbarContent = $(this).data('js-snackbar');
            bottomItemsPosition = '0px';
            snackbarTarget.find('.snackbar-content').html(snackbarContent);

            snackbarHeight = snackbarTarget.innerHeight();
            snackbarHeightPadding = snackbarHeight;
            backToTopHeight = snackbarHeightPadding + 25;

            snackbarTarget.css({visibility: "visible"}).stop().animate({opacity: 1, queue: false}, 500);
            $('#snackbar').css({bottom: snackbarHeight});

            $('.page-overlay-elements').addClass('active').css({bottom: -snackbarHeightPadding}).stop().animate({bottom: bottomItemsPosition,queue: false}, 500);
            $('.back-to-top').css({top: 'auto', bottom: backToTopHeight, position: 'absolute'});

            snackbarClose();
        }
    });

    var snackbarClose =  _.debounce(function() {
        $('.page-overlay-elements').stop().animate({bottom: - snackbarHeight}, 500, function () {$('.page-overlay-elements').removeClass('active')});
        $('.back-to-top').css({top: 'auto', bottom: backToTopHeight, position: 'absolute'});
    }, 3000);
});
